.App{
    font-family: 'Open Sans', sans-serif;
}
label, input{
    font-size: 18px;
}
.modal{
    .modal-dialog{
        max-width: 700px;
    }
    .modal-content{
        padding: 50px 60px;
        h2{
            font-size: 1.5rem;
            text-align: center;
            padding: 0 50px;
        }
        .space-md {
            height: 30px;
            width: 100%;
        }
        .close-modal{
            padding: 6px;
            border-radius: 50%;
            border: 1px solid;
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 1;
            line-height: .5;
        }
    }
}
.form-control{
    border-radius: 10px;
    padding: 10px 20px;
    &.is-invalid{
        border-radius: 10px !important;
        border-color: #dc3545;
        padding-right: calc(1.5em + .75rem);
        //background-image: url();
        background-repeat: no-repeat;
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #000080;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,0,128,.25);
}
.color_primario{
    color: #000080;
}
.color_secundario{
    color: #38b6ff;
}
.divider_titulo{
    width: 15%;
    float: left;
    border-top: 7px solid #38b6ff;
}
.titulo_container{
    padding: 3rem 1.5rem;
    text-align: center;
    h1{
        letter-spacing: -2px;
    }
}
.btn-outline-primary{
    color: #000080;
    &:hover, &:active{
        background-color: #000080 !important;
        color: #fff;
    }
}
.cta_container{
    padding: 3rem 1.5rem;
    text-align: center;
}
.big-btns-container{
    text-align: center;
    margin: auto;
    @media screen and (max-width: 1024px) {
        display: block!important;
    }
    .info-cont{
        margin: 0 7px;
        @media screen and (max-width: 1024px) {
            display: inline-table;
            margin: 10px;
        }
        a{
            color: #020145;
            margin-top: 10px;
            display: block;
            font-size: .8rem;
            text-decoration: underline;
        }
    }
    .big-btn2{
        padding: 30px;
        margin: 0 10px;
        border-radius: 10px;
        width: 180px;
        height: 200px;
        box-shadow: 0 3px 6px #aaa;
        background-color: #fff;
        border: 1px solid transparent;
        position: relative;
        cursor: pointer;
        transition: all .2s ease-in-out;
        @media screen and (max-width: 1024px) {
            padding: 20px 10px;
            height: 130px;
            width: 130px;
            min-width: 130px;
            min-height: 150px;
            margin: 0;
        }
        &.active{
            transform: translateY(-20px);
            background-color: rgba(0,0,128,.2);
            border: 1px solid #020145;
            box-shadow: 0 3px 6px #020145;
            i {
                visibility: visible;
                transform: scale(1.0);
            }
        }
        i{
            position: absolute;
            visibility: hidden;
            top: 10px;
            right: 10px;
            color: #020145;
            transform: scale(.1);
            transition: all .2s ease-in-out;
        }
        img{
            max-height: 75px;
            @media screen and (max-width: 1024px) {
                max-height: 50px;
            }
        }
        h3{
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .big-btn{
        padding: 30px;
        margin: 0 20px;
        border-radius: 10px;
        width: 180px;
        height: 200px;
        box-shadow: 0 3px 6px #aaa;
        background-color: #fff;
        border: 1px solid transparent;
        position: relative;
        cursor: pointer;
        transition: all .2s ease-in-out;
        @media screen and (max-width: 1024px) {
            padding: 20px 10px;
            height: 130px;
            width: 130px;
            min-width: 130px;
            min-height: 150px;
            margin: 0;
        }
        &.active{
            transform: translateY(-20px);
            background-color: rgba(0,0,128,.2);
            border: 1px solid #020145;
            box-shadow: 0 3px 6px #020145;
            i {
                visibility: visible;
                transform: scale(1.0);
            }
        }
        i{
            position: absolute;
            visibility: hidden;
            top: 10px;
            right: 10px;
            color: #020145;
            transform: scale(.1);
            transition: all .2s ease-in-out;
        }
        img{
            max-height: 75px;
            @media screen and (max-width: 1024px) {
                max-height: 50px;
            }
        }
        h3{
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}
.footer_wizard{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 110px;
    line-height: 60px;
    background-color: #f5f5f5;
    button{
        font-size: 18px;
        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }
}
h1{
    @media screen and (max-width: 768px) {
        font-size: 1.8em;
    }
}
.left-menu{
    position: relative;
    width: 100%;
    .left-menu-fixed{
        position: fixed;
        width: 16.66666667%;
        .menu-container{
            padding: 30px 15px;
            text-align: center;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .menu-items{
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    text-align: center;
                    padding: 20px 0;
                    color: #9C9C9C;
                    img{
                        display: inherit!important;
                        margin: 0 auto 5px;
                        width: 45px;
                    }
                    &.active{
                        background-color: #020145;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.background-app{
    background-color: #fbfafa;
    padding: 50px;
    height: 100%;
    min-height: 100vh;
    .reconoser-app{
        background-color: #fff;
        padding: 15px;
        text-align: center;
    }
    .content-app{
        background-color: #fff;
        padding: 50px;
        text-align: center;
        button{
            border: none;
        }
        .bg-boton-volver {
            background-color: transparent;
            color: #9C9C9C;
            position: absolute;
            z-index: 1;
            i{
                font-size: 20px;
                padding-right: 10px;
                color: #020145;
            }
        }
        .bg-boton__med {
            width: 179px!important;
            border-radius: 10px!important;
            font-size: 19px!important;
            font-weight: 100!important;
        }
    }
    .footer-app{
        padding: 10px 0;
        text-align: center;
        margin-top: 40px;
        p{
            font-size: .8rem;
            margin: 0;
        }
    }
}
.bg_slider{
    background-image: url('./assets/slider/bg_slider.png')
}
:root {
    --width: 100vw;
    --full-width: 100vw;
    @media (min-width: 80em) {
      --width: 80rem;
    }
    --angle: -1deg;
    --magic-number: 0.038595;
    --skew-padding: calc(var(--width) * var(--magic-number));
    --clip-padding: calc(var(--full-width) * var(--magic-number));
}
.diagonal-box {
    position: relative;
    top: -70px;
    padding: var(--skew-padding) 0;
    margin-top: -1px;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: skewy(var(--angle));
        transform-origin: 50% 0;
        outline: 1px solid transparent;
        backface-visibility: hidden;
        background-image: linear-gradient(45deg, #4FD1EE, #39B8FD);
    }
}
.content {
    max-width: var(--width);
    margin: 0 auto;
    padding: 1.5em;
    position: relative;
    //border: 2px dashed #fff8;
    p{
        color: #fff;
        font-size: 20px;
    }
}
.float_text{
    position: absolute;
    text-align: center;
    width: 50%;
    right: 0;
    h1{
        font-size: 55px;
        line-height: 0.9;
        @media screen and (max-width: 768px) {
            font-size: 40px;
        }
        @media screen and (max-width: 576px) {
            font-size: 30px;
        }
    }
}
.mobile_credito_container{
    width: 60%;
    position: absolute;
    left: -10%;
    top: 25%;
    @media screen and (max-width: 576px) {
        width: 55%;
        left: 0%;
        top: 0%;
    }
    h1{
        font-size: 25px;
    }
}
.header-main{
    height: 101px;
    z-index: 10;
    position: relative;
    box-shadow: 1px 1px 10px #00000026;
    .header-inner{
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        .logo{
            margin-left: 0;
            margin-right: 30px;
            line-height: 1;
            margin: 0;
            align-self: center;
            img{
                max-width: 306px;
            }
        }
        .header-navigation{
            margin-left: auto;
            ul{
                margin: 0;
                padding: 0;
                justify-content: flex-end;
                .menu-item{
                    font-family: "Open Sans", sans-serif;
                    display: inline-block;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    margin: 0 11px;
                    &:hover{
                        background-color: #38b6ff;
                        a{
                            color: #FFF!important;
                            text-decoration: none;
                        }
                    }
                    &.active{
                        background-color: #38b6ff;
                        a{
                            color: #FFF!important;
                        }
                    }
                    a{
                        color: #042247;
                        font-weight: 600;
                        line-height: 30px;
                        font-size: .8em;
                        padding: 0 .75em;
                        letter-spacing: .02em;
                        text-transform: uppercase;
                        
                    }
                }
            }
            
        }
    }
}
.navbar-toggler{
    border: 1px solid silver;
}
.navbar-toggler-icon {
    background-image: url('./assets/hamburguer.svg');
}
#navbarNav{
    font-family: "Open Sans", sans-serif;
    ul{
        padding: 15px;
        li{
            margin: 10px;
            a{
                color: #042247;
                font-weight: 600;
                line-height: 30px;
                font-size: 15px;
                padding: 0 .75em;
                letter-spacing: .02em;
                text-transform: uppercase;
            }
        }
        
    }
}
select.form-control{
    height: auto !important;
}
.campo_archivo{
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #ced4da;
    label{
        margin: 0;
        color: #000080;
    }
}
@keyframes spinner-border {
    to { transform: rotate(360deg); }
}
.loader{
    position: fixed;
    width: 100%;
    background-color: #ffffff91;
    height: 100%;
    z-index: 99999;
    .container{
        padding-top: 60px;
        .spinner-border {
            display: inline-block;
            width: 5rem;
            height: 5rem;
            vertical-align: text-bottom;
            border: .80em solid currentColor;
            border-right-color: transparent;
            border-radius: 50%;
            animation: spinner-border .75s linear infinite;
        }
    }
}


.react-autosuggest__container {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.react-autosuggest__input {
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}
.react-autosuggest__input--focused {
    outline: none;
}
.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
    display: none;
}
.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 45px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}